import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { downloadFile } from '../utils/fileUtils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentPointOrganizers } from '../redux/slices/paymentPointsSlice';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AssessmentIcon from '@mui/icons-material/Assessment';
import api from '../api';

export default function SaleReportsDialog({ open, onClose }) {
  const [selectedOrganizer, setSelectedOrganizer] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [ordersFrom, setOrdersFrom] = useState(null);
  const [ordersTo, setOrdersTo] = useState(null);
  const [eventsFrom, setEventsFrom] = useState(null);
  const [eventsTo, setEventsTo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const paymentPointOrganizers = useSelector((state) => state.paymentPoints.paymentPointOrganizers);
  const user = useSelector((state) => state.auth.user);

  const downloadDisabled = useMemo(() => {
    if (user.role === 'cashier') {
      return !selectedType || !selectedOrganizer || !ordersFrom || !ordersTo;
    } else if (user.role === 'organizer') {
      return !selectedType || !ordersFrom || !ordersTo || !eventsFrom || !eventsTo;
    } else {
      return true;
    }
  }, [user.role, selectedType, selectedOrganizer, ordersFrom, ordersTo, eventsFrom, eventsTo]);

  const onCloseInternal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setSelectedOrganizer('');
      setSelectedType('');
      setOrdersFrom(null);
      setOrdersTo(null);
      setEventsFrom(null);
      setEventsTo(null);
      onClose();
    }
  };

  const onConfirmInternal = () => {
    setIsLoading(true);
    api
      .get(
        `/events/saleReports?type=${encodeURIComponent(selectedType)}&orders_from=${encodeURIComponent(
          ordersFrom.toISOString()
        )}&orders_to=${encodeURIComponent(ordersTo.toISOString())}&events_from=${encodeURIComponent(
          eventsFrom.toISOString()
        )}&events_to=${encodeURIComponent(eventsTo.toISOString())}&organizer_id=${encodeURIComponent(
          selectedOrganizer
        )}`,
        { responseType: 'blob' }
      )
      .then((res) => {
        const data = res.data;
        setIsLoading(false);
        downloadFile(data, 'application/pdf', `${selectedType.toLowerCase()}_report_${dayjs().unix()}.pdf`);
        onCloseInternal();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  useEffect(() => {
    if (user) {
      if (user.role === 'cashier') {
        dispatch(fetchPaymentPointOrganizers({ paymentPointId: user.payment_point_id }));
      }
    }
  }, [dispatch, user, user.role]);

  const reportTypes = useMemo(() => {
    if (user.role === 'cashier') {
      return [
        {
          id: 'cashRegister',
          value: 'Cash Register',
        },
      ];
    } else if (user.role === 'organizer') {
      return [
        {
          id: 'detailedSales',
          value: 'Detailed Sales',
        },
        {
          id: 'sales',
          value: 'Sales',
        },
      ];
    }
  }, [user.role]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onCloseInternal}>
      <DialogTitle gutterBottom>
        <Box display="flex" alignItems="center">
          <AssessmentIcon sx={{ marginRight: 1 }} />
          Sale Reports
        </Box>
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          sx={{ marginBottom: 2 }}
          value={selectedType}
          onChange={(event) => setSelectedType(event.target.value)}
          fullWidth
          select
          label="Type"
        >
          {reportTypes.map(({ id, value }) => (
            <MenuItem key={id} value={id}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        {user.role === 'cashier' && (
          <TextField
            sx={{ marginBottom: 2 }}
            value={selectedOrganizer}
            onChange={(event) => setSelectedOrganizer(event.target.value)}
            fullWidth
            select
            label="Organizer"
          >
            {paymentPointOrganizers.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {user.role === 'organizer' && (
          <>
            <Divider sx={{ marginBottom: 2 }}>Events</Divider>
            <DateTimePicker
              sx={{ marginBottom: 2 }}
              value={eventsFrom}
              onChange={(value) => setEventsFrom(value)}
              maxDateTime={eventsTo}
              slotProps={{ textField: { label: 'From', fullWidth: true, variant: 'outlined' } }}
            />
            <DateTimePicker
              sx={{ marginBottom: 2 }}
              value={eventsTo}
              onChange={(value) => setEventsTo(value)}
              minDateTime={eventsFrom}
              slotProps={{ textField: { label: 'To', fullWidth: true, variant: 'outlined' } }}
            />
          </>
        )}
        <Divider sx={{ marginBottom: 2 }}>Orders</Divider>
        <DateTimePicker
          sx={{ marginBottom: 2 }}
          value={ordersFrom}
          onChange={(value) => setOrdersFrom(value)}
          disableFuture
          slotProps={{ textField: { label: 'From', fullWidth: true, variant: 'outlined' } }}
        />
        <DateTimePicker
          sx={{ marginBottom: 2 }}
          value={ordersTo}
          onChange={(value) => setOrdersTo(value)}
          disableFuture
          slotProps={{ textField: { label: 'To', fullWidth: true, variant: 'outlined' } }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading || downloadDisabled} onClick={onConfirmInternal} variant="contained">
          Download
          {isLoading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
