import dayjs from 'dayjs';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, setFilters, updateOrder } from '../redux/slices/ordersSlice';
import { toast } from 'react-toastify';
//import { fetchPlays } from '../redux/slices/playsSlice';
import { fetchCities } from '../redux/slices/citiesSlice';
import { categoryTypeFormatEn, seatIdToSectionParentOwn } from '../utils/formatters';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NoData from '../components/NoData';
import WarningDialog from '../components/WarningDialog';
import TicketPdfSelectorDialog from '../components/TicketPDFSelectorDialog';

function Row(props) {
  const { row } = props;
  const dispatch = useDispatch();
  const isUpdating = useSelector((state) => state.orders.isUpdating);
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [selectedOrdId, setSelectedOrdId] = useState(null);
  const [openCancelOrderDialog, setOpenCancelWarningDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCancelOrder = () => {
    setOpenCancelWarningDialog(true);
    handleClose(true);
  };

  const handleCloseCancelOrder = () => {
    setOpenCancelWarningDialog(false);
  };

  const handleOnConfirmCancel = () => {
    dispatch(updateOrder({ order_id: row.id, status: 'REFUNDED' })).then(({ payload }) => {
      if (payload) {
        handleCloseCancelOrder();
        toast.success('Order cancelled successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
      }
    });
  };

  const statusChip = useMemo(() => {
    if (!row.status) return null;
    const statusText = row.status.replace('_', ' ');
    const statusColor = row.status === 'COMPLETED' ? 'success' : row.status === 'PENDING' ? 'warning' : 'error';

    return (
      <Chip
        sx={{ mt: 1, borderRadius: 1 }}
        size="small"
        label={`${statusText}`}
        variant="outlined"
        color={statusColor}
      />
    );
  }, [row]);

  return (
    <Fragment>
      <TableRow
        sx={{
          '& > td': { whiteSpace: 'nowrap', borderBottom: 'unset' },
          '&:nth-of-type(4n+1)': (theme) => ({
            backgroundColor: theme.palette.action.hover,
          }),
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            disabled={!row.tickets.length}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>
          {row.tickets.length && row.status === 'COMPLETED' ? (
            <Link onClick={() => setSelectedOrdId(row.id)} sx={{ color: 'white', cursor: 'pointer' }} underline="hover">
              {row.id}
            </Link>
          ) : (
            row.id
          )}
        </TableCell>
        {user.role !== 'organizer' && <TableCell>{row.organizer}</TableCell>}
        <TableCell>{row.order_reference}</TableCell>
        {user.role === 'admin' && <TableCell>{row.payment_rrn || '-'}</TableCell>}
        <TableCell>{dayjs(row.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
        <TableCell>{dayjs(row.updated_at).format('DD/MM/YYYY HH:mm')}</TableCell>
        <TableCell>{row.total + ' Lei'}</TableCell>
        <TableCell>
          {user.role !== 'admin' ? (
            <Link
              underline="hover"
              href={`events/${row.events_id}`}
              sx={{ color: 'white', cursor: 'pointer' }}
            >{`${row.title} - ${row.city}`}</Link>
          ) : (
            `${row.title} - ${row.city}`
          )}
        </TableCell>
        <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.phone}</TableCell>
        {user.role !== 'cashier' && (
          <TableCell>{row.is_cashier ? 'cashier' : row.is_invite ? 'invite' : 'online'}</TableCell>
        )}
        <TableCell>{statusChip}</TableCell>
        {user.role === 'admin' ||
          (user.role === 'cashier' && (
            <TableCell>
              <IconButton size="small" aria-label="more options" onClick={handleMenu}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={!!anchorEl}
                onClose={handleClose}
              >
                <MenuItem disabled={row.status !== 'COMPLETED'} onClick={handleOpenCancelOrder}>
                  Cancel Order
                </MenuItem>
              </Menu>
            </TableCell>
          ))}
      </TableRow>
      <TableRow sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ ml: 8, mr: 8, mb: 4, mt: 4 }}>
              <Typography variant="h6" gutterBottom component="div">
                Tickets
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Price (Lei)</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Serial</TableCell>
                    <TableCell>Fiscal Serial</TableCell>
                    <TableCell>Seat ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.tickets.map((ticket) => (
                    <TableRow key={ticket.id}>
                      <TableCell component="th" scope="row">
                        {ticket.price + ' Lei'}
                      </TableCell>
                      <TableCell>
                        {ticket.category_name}
                        {ticket.type !== 'REGULAR' && (
                          <Chip sx={{ marginLeft: 1 }} size="small" label={categoryTypeFormatEn(ticket.type)} />
                        )}
                      </TableCell>
                      <TableCell>{ticket.serial}</TableCell>
                      <TableCell>{ticket.fiscal_serial || '-'}</TableCell>
                      <TableCell>
                        {seatIdToSectionParentOwn(ticket.seatsio_object_id, ticket.section, ticket.parent, ticket.own)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TicketPdfSelectorDialog orderId={selectedOrdId} onClose={() => setSelectedOrdId(null)} />
      <WarningDialog
        title={`Cancel order #${row.id}`}
        content={'By pressing confirm you will release the booked seats! Please dont forget to refund the user.'}
        loading={isUpdating}
        open={openCancelOrderDialog}
        onCancel={handleCloseCancelOrder}
        onConfirm={handleOnConfirmCancel}
      />
    </Fragment>
  );
}

function OrdersTable({ orders }) {
  const user = useSelector((state) => state.auth.user);

  return orders.length ? (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ '& > th': { whiteSpace: 'nowrap', borderBottom: 'unset' } }}>
            <TableCell sx={{ width: 50 }} />
            <TableCell>Order ID</TableCell>
            {user.role !== 'organizer' && <TableCell>Organizer</TableCell>}
            <TableCell>Order Ref</TableCell>
            {user.role === 'admin' && <TableCell>Payment Ref</TableCell>}
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Total (Lei)</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            {user.role !== 'cashier' && <TableCell>Type</TableCell>}
            <TableCell>Status</TableCell>
            {user.role === 'admin' && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <Row key={order.id} row={order} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <NoData />
  );
}

export default function Orders() {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders.data);
  const ordersError = useSelector((state) => state.orders.error);
  const isLoading = useSelector((state) => state.orders.isLoading);

  const user = useSelector((state) => state.auth.user);

  const options = useSelector((state) => ({
    ...state.orders.options,
    to: dayjs(state.orders.options.to),
    from: dayjs(state.orders.options.from),
  }));

  const cityData = useSelector((state) => state.cities.data);
  const cityDataError = useSelector((state) => state.cities.error);
  const citiesDataLoading = useSelector((state) => state.cities.isLoading);

  //const playsData = useSelector(({ plays: { data } }) => data.map(({ id, name }) => ({ id, name })));
  //const playsDataLoading = useSelector(({ plays: { isLoading } }) => isLoading);
  const playsDataError = useSelector(({ plays: { error } }) => error);

  useEffect(() => {
    //dispatch(fetchPlays());
    dispatch(fetchCities());
  }, [dispatch]);

  useEffect(() => {
    if (playsDataError || cityDataError || ordersError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [playsDataError, cityDataError, ordersError]);

  const searchHelperText = useMemo(
    () =>
      user.role === 'admin'
        ? 'Search using order id, event id, name, email, phone or RRN'
        : 'Search using order id, event id, name, email, phone',
    [user.role]
  );

  return (
    <div className="orders">
      <Typography variant="h3">Orders</Typography>
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                onChange={(event) => dispatch(setFilters({ search: event.target.value }))}
                value={options.search}
                sx={{ marginRight: 4 }}
                inputProps={{ autoComplete: 'off' }}
                label="Search"
                fullWidth
                placeholder={searchHelperText}
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} md>
              <DateTimePicker
                disableFuture
                onChange={(value) => dispatch(setFilters({ from: value.toISOString() }))}
                value={options.from}
                sx={{ marginRight: 3 }}
                slotProps={{ textField: { label: 'From', fullWidth: true, variant: 'outlined' } }}
              />
            </Grid>
            <Grid item xs={12} md>
              <DateTimePicker
                disableFuture
                onChange={(value) => dispatch(setFilters({ to: value.toISOString() }))}
                value={options.to}
                sx={{ marginRight: 3 }}
                slotProps={{ textField: { label: 'To', fullWidth: true, variant: 'outlined' } }}
              />
            </Grid>
            {/*<Grid item xs>
              <TextField
                onChange={(event) => dispatch(setFilters({ play: event.target.value }))}
                value={options.play}
                sx={{ marginRight: 3 }}
                disabled={playsDataLoading}
                label="Play"
                fullWidth
                select
                variant="outlined"
                type="text"
              >
                <MenuItem key={0} value={0}>
                  All
                </MenuItem>
                {playsData.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
                </TextField>
                </Grid>*/}
            <Grid item xs={12} md>
              <TextField
                onChange={(event) => dispatch(setFilters({ city: event.target.value }))}
                value={options.city}
                sx={{ marginRight: 3 }}
                disabled={citiesDataLoading}
                label="City"
                fullWidth
                select
                variant="outlined"
                type="text"
              >
                <MenuItem key={0} value={'All'}>
                  All
                </MenuItem>
                {cityData.map(({ id, name }) => (
                  <MenuItem key={id} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md>
              <TextField
                onChange={(event) => dispatch(setFilters({ status: event.target.value }))}
                value={options.status}
                sx={{ marginRight: 3 }}
                label="Status"
                fullWidth
                select
                variant="outlined"
                type="text"
              >
                <MenuItem key={0} value={'ALL'}>
                  All
                </MenuItem>
                <MenuItem key={1} value={'COMPLETED'}>
                  COMPLETED
                </MenuItem>
                {user.role === 'admin' && (
                  <MenuItem key={2} value={'PAYMENT_FAILURE'}>
                    PAYMENT FAILURE
                  </MenuItem>
                )}
                {user.role === 'admin' && (
                  <MenuItem key={3} value={'FAILED'}>
                    FAILED
                  </MenuItem>
                )}
                {user.role === 'admin' && (
                  <MenuItem key={4} value={'PENDING'}>
                    PENDING
                  </MenuItem>
                )}
                <MenuItem key={5} value={'REFUNDED'}>
                  REFUNDED
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md>
              <div>
                <Button
                  disabled={isLoading}
                  fullWidth
                  onClick={() => dispatch(fetchOrders(options))}
                  size="large"
                  variant="contained"
                >
                  Search
                  {isLoading && <CircularProgress size={24} sx={{ ml: 1, color: 'white' }} />}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <OrdersTable orders={orders} />
      </Box>
    </div>
  );
}
